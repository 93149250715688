const conditionalRadios = document.querySelectorAll('.js-conditional-radio');

conditionalRadios.forEach(function (wrapper) {
    const radio = wrapper.querySelectorAll('.js-conditional-radio input[type=radio]');
    const target = wrapper.querySelector('.js-conditional-radio .js-conditional-radio-target');

    radio.forEach(function (input) {
        if (input.checked === true && input.value === 'true') {
            target.style.display = 'block';
        }
        input.addEventListener('change', function (e) {
            if (e.target.value === 'true') {
                target.style.display = 'block';
            } else {
                target.style.display = 'none';
            }
        });
    });
});
