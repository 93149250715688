// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
require.context('../images', true);

require('../stylesheets/application.css');

// Common configuration for Bugsnag
const bugsnagConfig = {
    apiKey: process.env.BUGSNAG_FRONTEND_API_KEY || "invalid"
};

Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.RELEASE_STAGE,
    enabledReleaseStages: ["production", "staging", "review_app"],
});

// Starting Bugsnag for performance monitoring
BugsnagPerformance.start(bugsnagConfig);

document.addEventListener('DOMContentLoaded', function (event) {
    require('../src/forms');
});
